
import { Options, Vue } from "vue-class-component";
import { Cl_common } from "@/functions/Cl_common";
import { commonDate } from "xcommon-node/src/common/commonDate";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";

//掲示板関係
import { Cl_message } from "@/functions/Cl_message";
import { Cl_alert } from "@/functions/Cl_alert";
import { DtoMessagesGetReqImple } from "mediadept-common/src/common/dto/DtoMessagesGetReqImple";
import { DtoMessagesGetResImple } from "mediadept-common/src/common/dto/DtoMessagesGetResImple";
import { DtoAlertsGetReqImple } from "mediadept-common/src/common/dto/DtoAlertsGetReqImple";
import { DtoAlertsGetResImple } from "mediadept-common/src/common/dto/DtoAlertsGetResImple";
import { DtoAlertsPutReq } from "mediadept-common/src/common/dto/abst/DtoAlertsPutReq";

//売上進捗状況関係
import { Cl_salesinfo } from "@/functions/Cl_salesinfo";
import { DtoSalesInfosGetReq } from "mediadept-common/src/common/dto/abst/DtoSalesInfosGetReq";
import { DtoSalesInfosGetRes } from "mediadept-common/src/common/dto/abst/DtoSalesInfosGetRes";

//勤怠関係
import { Cl_attendanceImple } from "@/functions/Cl_attendanceImple";
import { DtoAttendancesGetReqImple } from "mediadept-common/src/common/dto/DtoAttendancesGetReqImple";
import { DtoAttendancesGetResImple } from "mediadept-common/src/common/dto/DtoAttendancesGetResImple";
import { DtoAttendancesPostReqImple } from "mediadept-common/src/common/dto/DtoAttendancesPostReqImple";
import { DtoAttendancesPostResImple } from "mediadept-common/src/common/dto/DtoAttendancesPostResImple";
import { DtoStaff4attendancesGetResImple } from "mediadept-common/src/common/dto/DtoStaff4attendancesGetResImple";
import { EntityStaff4attendanceImple } from "mediadept-common/src/common/entity/EntityStaff4attendanceImple";
import {
  CONST_ATTENDANCE_GETMODE_MINE,
  CONST_ATTENDANCE_GETMODE_VIEW,
  CONST_ATTENDANCE_POSTMODE_GOOUTEND,
  CONST_ATTENDANCE_POSTMODE_GOOUTEND_NUM,
  CONST_ATTENDANCE_POSTMODE_GOOUTSTART,
  CONST_ATTENDANCE_POSTMODE_GOOUTSTART_NUM,
  CONST_ATTENDANCE_POSTMODE_LEAVING,
  CONST_ATTENDANCE_POSTMODE_LEAVING_NUM,
  CONST_ATTENDANCE_POSTMODE_WORK,
  CONST_ATTENDANCE_POSTMODE_WORK_NUM,
  CONST_ATTENDANCE_STATUS_GAISYUTU,
  CONST_ATTENDANCE_STATUS_MISYUKIN,
  CONST_ATTENDANCE_STATUS_SYUKIN,
  CONST_ATTENDANCE_STATUS_TAIKIN,
  CONST_OP_HOLIDAY_PART_TYPE,
  CONST_OP_HOLIDAY_TYPE,
  CONST_OP_OVERTIME_TYPE,
  getAttendanceStatusStr,
} from "mediadept-common/src/common/constant/Constants";

@Options({
  components: {
    Mo_modal_alert,
  },
})
export default class Dashboard extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  //掲示板関係
  delegate4Message = new DtoMessagesGetResImple();
  //アラート関係
  delegate4Alert = new DtoAlertsGetResImple();
  //売上進捗状関係
  delegate4SalesInfo = new DtoSalesInfosGetRes();
  //勤怠関係
  responce4attendance = new DtoAttendancesGetResImple();
  responce4attendanceList = new DtoStaff4attendancesGetResImple();
  responce4attendanceListTmp: EntityStaff4attendanceImple[] = [];

  domYear = 0;
  domType = 1;

  type1 = "";
  type2 = "";
  type3 = "";
  type4 = "";
  type5 = "";
  type6 = "";
  type1prog = 0;
  type2prog = 0;
  type3prog = 0;
  type4prog = 0;
  type5prog = 0;
  type6prog = 0;
  salesTotal = "";
  budget = "";
  rate = "";

  domAttendanceFilter = "";

  //勤怠管理ボタン用
  //----------------
  isDisableWb = true;
  isDisableWb4work = true;
  isDisableWb4leaving = true;
  isDisableWb4gostart = true;
  isDisableWb4goend = true;

  attendaceStatus = "";

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    this.domYear = new Date().getFullYear();

    this.get4attendance();
    this.get4Message();
    this.get4Alert();
    this.get4SalesInfos();
    this.get4attendanceList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  async get4attendanceList() {
    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("panel-attendancelist");
    panel?.classList.add("enable-loader");
    this.isDisableWb = true;

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoAttendancesGetReqImple();
      dto.getmode = CONST_ATTENDANCE_GETMODE_VIEW;

      //API実行
      //-------
      this.responce4attendanceList =
        await Cl_attendanceImple.get4AttendanceList(dto);

      // Cl_common.logger(this.responce4attendanceList);

      //フィルタリング
      //-------
      this.responce4attendanceListTmp =
        this.responce4attendanceList.items.filter((item) =>
          item.c_staff_name!.includes(this.domAttendanceFilter)
        );

      //ボタンの状態更新
      //-------
      await this.updWorkBtn();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
      this.isDisableWb = false;
    }
  }

  //--------------------------------
  /**
   * 勤怠ボタンを読み込む
   */
  async get4attendance() {
    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("loader_attendance");
    panel?.classList.add("enable-loader_attendance");
    this.isDisableWb = true;

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoAttendancesGetReqImple();
      dto.getmode = CONST_ATTENDANCE_GETMODE_MINE;

      //API実行
      //-------
      this.responce4attendance = await Cl_attendanceImple.get4Attendance(dto);

      //ボタンの状態更新
      //-------
      await this.updWorkBtn();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader_attendance");
      this.isDisableWb = false;
    }
  }
  /**
   * 勤怠管理関係のボタンの状態を更新する
   */
  async updWorkBtn() {
    //初期化
    //-------------

    this.isDisableWb4work = true;
    this.isDisableWb4leaving = true;
    this.isDisableWb4gostart = true;
    this.isDisableWb4goend = true;
    this.attendaceStatus = "";

    //データ判定
    //-------------
    if (this.responce4attendance == null) {
      this.isDisableWb4work = true;
      this.isDisableWb4leaving = true;
      this.isDisableWb4gostart = true;
      this.isDisableWb4goend = true;
      return;
    }

    //状態判定
    //-------------
    try {
      let status = getAttendanceStatusStr(
        this.responce4attendance.c_attendance_time_work,
        this.responce4attendance.c_attendance_time_leaving,
        this.responce4attendance.c_attendance_time_goout_start,
        this.responce4attendance.c_attendance_time_goout_end
      );

      switch (status) {
        case CONST_ATTENDANCE_STATUS_MISYUKIN:
          this.isDisableWb4work = false;
          this.isDisableWb4leaving = false; //未出勤状態でも退勤打刻可能（例外機能）
          this.isDisableWb4gostart = true;
          this.isDisableWb4goend = true;

          this.attendaceStatus = CONST_ATTENDANCE_STATUS_MISYUKIN;

          break;
        case CONST_ATTENDANCE_STATUS_GAISYUTU:
          this.isDisableWb4work = true;
          this.isDisableWb4leaving = true;
          this.isDisableWb4gostart = true;
          this.isDisableWb4goend = false;

          this.attendaceStatus = CONST_ATTENDANCE_STATUS_GAISYUTU;

          break;
        case CONST_ATTENDANCE_STATUS_SYUKIN:
          this.isDisableWb4work = true;
          this.isDisableWb4leaving = false;

          if (this.responce4attendance.c_attendance_time_goout_start != null) {
            //外出済み
            this.isDisableWb4gostart = true;
            this.isDisableWb4goend = true;
          } else {
            //未外出
            this.isDisableWb4gostart = false;
            this.isDisableWb4goend = true;
          }

          this.attendaceStatus = CONST_ATTENDANCE_STATUS_SYUKIN;

          break;
        case CONST_ATTENDANCE_STATUS_TAIKIN:
          this.isDisableWb4work = true;
          this.isDisableWb4leaving = true;
          this.isDisableWb4gostart = true;
          this.isDisableWb4goend = true;

          this.attendaceStatus = CONST_ATTENDANCE_STATUS_TAIKIN;

          break;
        default:
          throw new Error("不正な状態");
      }
    } catch (error) {
      Cl_common.logger("勤怠管理用ボタンでerror");
      this.isDisableWb = true;
      this.isDisableWb4work = true;
      this.isDisableWb4leaving = true;
      this.isDisableWb4gostart = true;
      this.isDisableWb4goend = true;
    }
  }

  /**
   * GPS情報を取得する
   */
  async getGeolocation(): Promise<{
    latitude: number;
    longitude: number;
  } | null> {
    if (!navigator.geolocation) {
      alert("このブラウザは位置情報の取得に対応していません。");
      return null;
    }

    let location: {
      latitude: number;
      longitude: number;
    } | null = null;
    try {
      // eslint-disable-next-line no-undef
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );

      location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    } catch (error) {
      console.error(error);
      // alert("位置情報の取得に失敗しました。");
      return null;
    }

    return location;
  }

  /**
   * 勤怠情報を更新する
   * 1:出勤ボタン
   * 2:退勤ボタン
   * 3:外出ボタン
   * 4:戻りボタン
   */
  async updAttendance(mode: number, isForceLeav: boolean) {
    console.log(location);

    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("loader_attendance");
    panel?.classList.add("enable-loader_attendance");
    this.isDisableWb = true;

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoAttendancesPostReqImple();
      let status: string;

      switch (mode) {
        case CONST_ATTENDANCE_POSTMODE_WORK_NUM:
          dto.postmode = CONST_ATTENDANCE_POSTMODE_WORK;
          break;
        case CONST_ATTENDANCE_POSTMODE_LEAVING_NUM:
          //強制退勤確認
          status = getAttendanceStatusStr(
            this.responce4attendance.c_attendance_time_work,
            this.responce4attendance.c_attendance_time_leaving,
            this.responce4attendance.c_attendance_time_goout_start,
            this.responce4attendance.c_attendance_time_goout_end
          );

          if (!isForceLeav && status == CONST_ATTENDANCE_STATUS_MISYUKIN) {
            //未出勤状態での退勤操作なので確認ダイアログを表示
            //モーダル表示
            const modal: any = this.$refs.Mo_modal_forceLeav;
            modal.showModal();
            return;
          }

          //強制退勤打刻モード
          if (isForceLeav) {
            this.closeModal4ForceLeav();
            dto.isForceLeav = 1;
          }

          dto.postmode = CONST_ATTENDANCE_POSTMODE_LEAVING;
          break;
        case CONST_ATTENDANCE_POSTMODE_GOOUTSTART_NUM:
          dto.postmode = CONST_ATTENDANCE_POSTMODE_GOOUTSTART;
          break;
        case CONST_ATTENDANCE_POSTMODE_GOOUTEND_NUM:
          dto.postmode = CONST_ATTENDANCE_POSTMODE_GOOUTEND;
          break;

        default:
          throw new Error("不正なモード");
      }

      //GPS情報取得
      //-------
      const location = await this.getGeolocation();
      Cl_common.logger(location);
      //locationがnull=GPS機能が動作しない場合は打刻不可
      if (location == null) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf =
          "GPS情報を取得できませんでした、GPSの使用を許可してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();

        return;
      }

      dto.lati = location.latitude;
      dto.long = location.longitude;

      //API実行
      //-------
      const res = await Cl_attendanceImple.post4Attendance(dto);
      this.responce4attendance = Object.assign(
        new DtoAttendancesGetResImple(),
        res
      );

      // Cl_common.logger("this.responce4attendance");
      // Cl_common.logger(this.responce4attendance);

      //ボタンの状態更新
      //-------
      await this.updWorkBtn();
      //出勤状況一覧を更新
      //-------
      this.get4attendanceList();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader_attendance");
      this.isDisableWb = false;
    }
  }
  /**
   * 勤怠のデータを受け取り、ステータス表示部分のHTMLを返す
   * @param item
   */
  cnvData2State(item: EntityStaff4attendanceImple): string {
    let status = "";
    try {
      status = getAttendanceStatusStr(
        item.c_attendance_time_work,
        item.c_attendance_time_leaving,
        item.c_attendance_time_goout_start,
        item.c_attendance_time_goout_end
      );
    } catch (error) {
      status = "不正値";
    }

    switch (status) {
      case CONST_ATTENDANCE_STATUS_MISYUKIN:
        return '<span class="badge badge-secondary badge-pill fs-md">未出勤</span>';
      case CONST_ATTENDANCE_STATUS_SYUKIN:
        return '<span class="badge badge-info badge-pill fs-md">出勤中</span>';
      case CONST_ATTENDANCE_STATUS_GAISYUTU:
        return '<span class="badge badge-success badge-pill fs-md">外出中</span>';
      case CONST_ATTENDANCE_STATUS_TAIKIN:
        return '<span class="badge badge-danger badge-pill fs-md">退勤済</span>';
      case "不正値":
        return '<span class="badge badge-danger badge-pill fs-md">不正値</span>';
      default:
        return "";
    }
  }
  /**
   * 勤怠のデータを受け取り、申請表示部分のHTMLを返す
   * @param item
   */
  cnvData2Request(item: EntityStaff4attendanceImple): string {
    let res = "";
    let txtTmp: string | undefined = "";
    let txtTmpHoliday: string | undefined = "";

    // 残業申請。
    //-------
    for (let index = 0; index < item.item4requestOvertime.length; index++) {
      txtTmp = CONST_OP_OVERTIME_TYPE.find(
        (e) =>
          e.code === item.item4requestOvertime[index].c_request_overtime_type!
      )?.label;
      if (txtTmp == undefined) {
        txtTmp = "残業申請";
      }

      res +=
        '<a href="/shinsei_Zangyoedit?id=' +
        item.item4requestOvertime[index].c_request_id +
        '"><span class="badge badge-info badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestOvertime[index].c_request_id +
        "</span></a>";
    }

    // 休暇申請。
    //-------
    for (let index = 0; index < item.item4requestHoliday.length; index++) {
      txtTmp = CONST_OP_HOLIDAY_TYPE.find(
        (e) =>
          e.code === item.item4requestHoliday[index].c_request_holiday_type!
      )?.label;
      if (txtTmp == undefined) {
        txtTmp = "休暇申請";
      }
      if (item.item4requestHoliday[index].c_request_holiday_part_type != null) {
        txtTmpHoliday = CONST_OP_HOLIDAY_PART_TYPE.find(
          (e) =>
            e.code ===
            item.item4requestHoliday[index].c_request_holiday_part_type!
        )?.label;

        if (txtTmpHoliday != undefined) {
          txtTmp += ":" + txtTmpHoliday;
        }
      }

      res +=
        '<a href="/shinsei_Kyukatodokeedit?id=' +
        item.item4requestHoliday[index].c_request_id +
        '"><span class="badge badge-success badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestHoliday[index].c_request_id +
        "</span></a>";
    }

    //リモートワーク申請。
    //-------
    for (let index = 0; index < item.item4requestRemote.length; index++) {
      txtTmp = "リモート";

      res +=
        '<a href="/shinsei_Remoteworkedit?id=' +
        item.item4requestRemote[index].c_request_id +
        '"><span class="badge badge-primary badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestOvertime[index].c_request_id +
        "</span></a>";
    }

    //出張申請。
    //-------
    for (let index = 0; index < item.item4requestBiztrip.length; index++) {
      txtTmp = "出張";

      res +=
        '<a href="/shinsei_Shutchoedit?id=' +
        item.item4requestBiztrip[index].c_request_id +
        '"><span class="badge badge-secondary badge-pill fs-md">' +
        txtTmp +
        " " +
        // this.item.item4requestOvertime[index].c_request_id +
        "</span></a>";
    }

    return res;
  }

  //--------------------------------
  /**
   * 掲示板のデータを読み込む
   */
  async get4Message() {
    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("panel-message");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoMessagesGetReqImple();

      //検索条件設定
      const nowdate = new Date();
      dto.date = new Date(
        nowdate.getFullYear(),
        nowdate.getMonth(),
        nowdate.getDate()
      )
        .toLocaleDateString()
        .replaceAll("/", "-");

      dto.isDashboard = 1;

      //API実行
      //-------
      this.delegate4Message = await Cl_message.getList(dto);

      // console.log("this.delegate4Message");
      // console.log(this.delegate4Message);
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
    }
  }
  //--------------------------------
  /**
   * アラートのデータを読み込む
   */
  async get4Alert(): Promise<number> {
    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("panel-alert");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      this.delegate4Alert = new DtoAlertsGetResImple();
      let dto = new DtoAlertsGetReqImple();

      //検索条件設定
      const nowdate = new Date();
      dto.date = new Date(
        nowdate.getFullYear(),
        nowdate.getMonth(),
        nowdate.getDate()
      )
        .toLocaleDateString()
        .replaceAll("/", "-");

      dto.isDashboard = 1;

      //API実行
      //-------
      this.delegate4Alert = await Cl_alert.getList(dto);

      console.log("this.delegate4Alert");
      console.log(this.delegate4Alert);
    } catch (error) {
      this.delegate4Alert = new DtoAlertsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
    }

    return this.delegate4Alert.items.length;
  }
  /**
   * アラートのデータを既読にする
   */
  async upd4flagRead(id: number) {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      let dto = new DtoAlertsPutReq();

      dto.c_alert_id = id;
      dto.c_alert_flag_read = 1;

      //API実行
      //-------
      await Cl_alert.put(dto);
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //-----------------
    }
  }
  //--------------------------------
  /**
   * 売上進捗状況を読み込む
   */
  async get4SalesInfos() {
    //ボタン無効＋ローダー表示
    //-----------------
    const panel = document.getElementById("panel-profit");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      this.delegate4SalesInfo = new DtoSalesInfosGetRes();
      let dto = new DtoSalesInfosGetReq();

      //検索条件設定
      dto.year = this.domYear;
      dto.type = this.domType;

      //API実行
      //-------
      this.delegate4SalesInfo = await Cl_salesinfo.get(dto);

      this.type1 = "¥" + this.delegate4SalesInfo.type1.toLocaleString() + "-";
      this.type2 = "¥" + this.delegate4SalesInfo.type2.toLocaleString() + "-";
      this.type3 = "¥" + this.delegate4SalesInfo.type3.toLocaleString() + "-";
      this.type4 = "¥" + this.delegate4SalesInfo.type4.toLocaleString() + "-";
      this.type5 = "¥" + this.delegate4SalesInfo.type5.toLocaleString() + "-";
      this.type6 = "¥" + this.delegate4SalesInfo.type6.toLocaleString() + "-";
      this.type1prog =
        (this.delegate4SalesInfo.type1 / this.delegate4SalesInfo.salesTotal) *
        100;
      this.type2prog =
        (this.delegate4SalesInfo.type2 / this.delegate4SalesInfo.salesTotal) *
        100;
      this.type3prog =
        (this.delegate4SalesInfo.type3 / this.delegate4SalesInfo.salesTotal) *
        100;
      this.type4prog =
        (this.delegate4SalesInfo.type4 / this.delegate4SalesInfo.salesTotal) *
        100;
      this.type5prog =
        (this.delegate4SalesInfo.type5 / this.delegate4SalesInfo.salesTotal) *
        100;
      this.type6prog =
        (this.delegate4SalesInfo.type6 / this.delegate4SalesInfo.salesTotal) *
        100;
      this.salesTotal =
        "¥" + this.delegate4SalesInfo.salesTotal.toLocaleString() + "-";

      this.budget = "¥" + this.delegate4SalesInfo.budget.toLocaleString() + "-";
      if (
        0 != this.delegate4SalesInfo.salesTotal &&
        0 != this.delegate4SalesInfo.budget
        // !isNaN(
        //   (this.delegate4SalesInfo.salesTotal /
        //     this.delegate4SalesInfo.budget) *
        //     100
        // )
      ) {
        // カンマ削除
        this.delegate4SalesInfo.budget = Number(
          this.delegate4SalesInfo.budget.toString().replaceAll(",", "")
        );
        // 小数点第二位以下削除（切り上げ）
        this.rate =
          Number.parseFloat(
            (
              (this.delegate4SalesInfo.salesTotal /
                this.delegate4SalesInfo.budget) *
              100
            ).toString()
          ).toFixed(2) + "%";
      } else {
        this.rate = "0%";
      }
    } catch (error) {
      this.delegate4SalesInfo = new DtoSalesInfosGetRes();
      Cl_common.logger(error);
    } finally {
      //ローダー非表示
      //-----------------
      panel?.classList.remove("enable-loader");
    }
  }
  //--------------------------------

  async moveDet(link: string, type: number, id: number) {
    //タイプによって、画面遷移と同時に既読にする
    //-------------------
    if (type != 1 && type != 6) {
      await this.upd4flagRead(id);
    }

    //詳細ページに移動
    //-------------------
    this.$router.push(link);
  }
  /**
   * 日付のフォーマット調整
   * @param date
   */
  cnvDatetime2Date(date: string): string {
    return date.split("T")[0].replaceAll("-", "/");
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  async closeModal4ForceLeav(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_forceLeav;
    modal.closeModal();
  }
}
